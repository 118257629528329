import { createSelector, createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { selectCurrentAccessToken } from "@xcira/commons";

interface AvState {
  videoPlayers: Record<string, string>;
  isSaleSelectPopperOpen: boolean;
  selectedSaleAccessToken: string | null;
}

const initialState: AvState = {
  videoPlayers: {},
  isSaleSelectPopperOpen: false,
  selectedSaleAccessToken: null,
};

const avSlice = createSlice({
  name: "av",
  initialState,
  reducers: {
    setSelectedSaleAccessToken(state: Draft<AvState>, action) {
      state.selectedSaleAccessToken = action.payload;
    },
    closeSaleSelectPopper(state: Draft<AvState>) {
      state.isSaleSelectPopperOpen = false;
    },
    openSaleSelectPopper(state: Draft<AvState>) {
      state.isSaleSelectPopperOpen = true;
    },
    registerVideoPlayer(
      state: Draft<AvState>,
      action: PayloadAction<{ accessToken: string; videoPlayerId: string }>
    ) {
      state.videoPlayers[action.payload.accessToken] = action.payload.videoPlayerId;
    },
    unregisterVideoPlayer(state: Draft<AvState>, action) {
      delete state.videoPlayers[action.payload.accessToken];
    },
  },
});

export const selectIsSaleSelectPopperOpen = (state: RootState) => state.av.isSaleSelectPopperOpen;
export const selectVideoPlayerId = (state: RootState, accessToken: string) =>
  state.av.videoPlayers[accessToken];

export const selectSelectedSaleAccessToken = createSelector(
  (state: RootState) => state.av.selectedSaleAccessToken,
  selectCurrentAccessToken,
  (selectedAccessToken, currentAccessToken) => selectedAccessToken ?? currentAccessToken
);

export const selectSelectedVideoPlayerId = createSelector(
  (state: RootState) => state.av.videoPlayers,
  selectSelectedSaleAccessToken,
  (videoPlayers, selectedAccessToken) => videoPlayers[selectedAccessToken ?? ""]
);

export const {
  setSelectedSaleAccessToken,
  closeSaleSelectPopper,
  openSaleSelectPopper,
  registerVideoPlayer,
  unregisterVideoPlayer,
} = avSlice.actions;

export const { name: avReducerPath, reducer: avReducer } = avSlice;
